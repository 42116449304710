const regex = {
  name: new RegExp(/^[a-zA-Z ]*$/),
  address: new RegExp(/^[a-zA-Z0-9_\s.,:;/]*$/),
  lname: new RegExp(/^[a-zA-Z]+$/),
  email: new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  ),
  mobile: new RegExp(/^(9|8|7|6)([0-9]{9})$/),
  countryCode: new RegExp(/^[+|0][0-9]{2,4}$/),
  pin: new RegExp(/^[0-9]{6}$/),
  pan: new RegExp(/^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/),
  gst: new RegExp(
    /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[a-zA-Z0-9]{1}$/
  ),
  tin: new RegExp(
    /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[a-zA-Z0-9]{1}$/
  ),
  cin: new RegExp(
    /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[a-zA-Z0-9]{1}$/
  ),
  allName: new RegExp(/^[a-zA-Z0-9 ]*$/),
  password: new RegExp(
    /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,16}$/
  ),
  number: new RegExp(/^[0-9]*$/),
  allItem: new RegExp(/^[a-zA-Z0-9_\s-.,:;/]*$/),
  anyChar: new RegExp(/^[.]*/),
  website: new RegExp(
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
  ),
  //googlerating: new RegExp(/^[1-5](\.\d)?$/),
  googlerating: new RegExp(/^([1-4](\.[0-9])?|5(\.0)?)$/),
};

export default regex;
