"use client";

import React from "react";
import { Button, Input } from "antd";
import AntdModal from "@/components/common/Modal/AntdModal";
import PyrSuccessImage from "@/assets/images/pyrsuccess.png";
import Image from "next/image";
import { useRouter } from "next/navigation";


const PyrSuccess = ({
  showModal,
  setShowModal,
  pyrData,
  setPyrData,
  form,
  onSuccess,
  bookAppointment,
  isFromAdditional
}) => {
  const router = useRouter();
  return (
    <AntdModal
      showModal={showModal}
      handleCancelModal={() => {
        setPyrData({});
        setShowModal(false);
        const emailvl = form.getFieldValue('email')
        const mobvl = form.getFieldValue('mobile')
        form.resetFields();
        form.setFieldValue("email", emailvl)
        form.setFieldValue("mobile", mobvl)
        onSuccess?.();
      }}
      footer={null}
      modalTitle={null}
    >
      <div className="pyrotp_verify">
        <div className="flex justify-center">
          <Image
            src={PyrSuccessImage}
            alt="team"
            width={120}
            height={120}
            className="mb-3"
          />
        </div>
        <h5 className="mb-2 text-center font-sans text-lg font-medium text-color-primary-dark ">
          Thank you {pyrData?.name} for submitting your requirement
        </h5>
        {!isFromAdditional && <div className="text-center">
          <Button
            type="primary"
            block={true}
            size="large"
            className="mt-4 font-sans text-sm"
            onClick={bookAppointment}
          >
            Book Verification Call
          </Button>

        </div>}
        <div className="mb-4 flex flex-row items-center justify-center space-x-1 text-left text-sm font-medium text-gray-500">
          {!isFromAdditional ? <Button
            type="primary"
            htmlType="submit"
            block={true}
            size="large"
            className="mt-4 font-sans text-sm"
            onClick={() => router.push('/profile?activeSection="interest_in_business"')}
          >
            Add Additional Info
          </Button> :
            <Button
              type="primary"
              htmlType="submit"
              block={true}
              size="large"
              className="mt-4 font-sans text-sm"
              onClick={bookAppointment}
            >
              Book Verification Call
            </Button>

          }
          <Button
            type="primary"
            htmlType="submit"
            block={true}
            size="large"
            className="mt-4 font-sans text-sm"
            onClick={() => router.push('/my-orders')}
          >
            Manage your requirement
          </Button>
        </div>
      </div>
    </AntdModal >
  );
};

export default PyrSuccess;
