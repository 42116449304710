"use client";

import React, { useState } from "react";
import AntdModal from "@/components/common/Modal/AntdModal";
import { Button, Calendar, Row, Col, Select } from "antd";
import moment from "moment";
import toast from "react-hot-toast";
import useAuth from "@/hooks/useAuth";
import * as PYRServices from "@/services/PYRServices";

const BookAppointment = ({
  showModal,
  setShowModal,
  investorDetails,
  onSuccess,
}) => {
  const { user } = useAuth();

  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedTime, setSelectedTime] = useState(null);
  const onDateChange = (date) => {
    setSelectedDate(date.format("YYYY-MM-DD"));
    setSelectedTime(null);
  };

  const onTimeChange = (time) => {
    setSelectedTime(time);
  };
  const disabledDate = (current) => {
    const today = moment().startOf("day");
    const sevenDaysLater = moment().add(7, "days").endOf("day");
    return current < today || current > sevenDaysLater;
  };

  const generateTimeSlots = () => {
    const slots = [];
    const format = "hh:mm A"; // 12-hour format with AM/PM

    const start = moment().set({ hour: 10, minute: 0, second: 0 });
    const end = moment().set({ hour: 22, minute: 0, second: 0 });

    while (start.isBefore(end)) {
      slots.push(start.format(format));
      start.add(30, "minutes");
    }

    return slots;
  };

  const isTimeSlotDisabled = (timeSlot) => {
    if (!selectedDate) return true;

    const timeParts = timeSlot.split(" ");
    const [hourMinute, period] = timeParts;
    let [hour, minute] = hourMinute.split(":").map(Number);
    if (period === "PM" && hour !== 12) {
      hour += 12;
    } else if (period === "AM" && hour === 12) {
      hour = 0;
    }
    const selectedDateTime = moment(selectedDate).set({
      hour:
        parseInt(timeSlot.split(":")[0]) +
        (timeSlot.includes("PM") && parseInt(timeSlot.split(":")[0]) !== 12
          ? 12
          : 0),
      minute: parseInt(timeSlot.split(":")[1]),
      second: 0,
    });
    const now = moment();

    if (
      moment(selectedDate).isSame(now, "day") &&
      selectedDateTime.isBefore(now)
    ) {
      return true;
    }

    // Disable if it's today and the selected time has already passed
    if (selectedDateTime.isBefore(now)) {
      return true;
    }

    // Otherwise, enable the button
    return false;
  };

  const submitAppointment = async (date, timeSlot) => {
    try {
      if (user?._id || investorDetails?._id) {
        const payload = {
          investor_id: user?._id || investorDetails?._id || "",
          date: moment(date).format("YYYY-MM-DD"),
          time: moment(timeSlot, "hh:mm A").format("HH:mm"),
        };
        const response = await PYRServices.addAppointment(payload);
        console.log("responseresponse", response);
        if (response?.status === "Success") {
          const day = moment(date, "YYYY-MM-DD").format("Do");
          const formattedDate = `${day} ${moment(date).format("MMMM")}`;
          toast.success(
            `Thank you for reaching out! Our team will contact you at ${formattedDate} ${timeSlot}. We look forward to speaking with you!`
          );
          setShowModal(false);
        }
      } else {
        toast.error("Investor id is missing");
      }
    } catch (error) {
      toast.error(error?.response?.message || "Something went wrong");
    }
  };

  const handleBookAppointment = () => {
    // Perform booking appointment logic

    if (selectedDate && selectedTime) {
      submitAppointment(selectedDate, selectedTime);
      // setShowModal(false);
      setSelectedDate(null); // Reset selected date after success
      setSelectedTime(null); // Reset selected time after success
    } else {
      // Show warning notification if date or time is not selected
      toast.error(
        "Please choose a date and time slot to book your appointment."
      );
    }
  };
  const availableSlots = () =>
    generateTimeSlots()?.filter((time) => !isTimeSlotDisabled(time));
  const isSubmitDisabled =
    !selectedDate ||
    !selectedTime ||
    moment(selectedDate).isAfter(moment().add(7, "days"));

  return (
    <AntdModal
      showModal={showModal}
      handleCancelModal={() => {
        setShowModal(false);
      }}
      footer={[
        <Button key="cancel" onClick={() => setShowModal(false)}>
          Cancel
        </Button>,
        <Button
          disabled={isSubmitDisabled}
          key="book"
          type="primary"
          onClick={handleBookAppointment}
        >
          Submit
        </Button>,
      ]}
      modalTitle={null}
    >
      <h3>Choose Date</h3>
      <Calendar
        headerRender={({ value, type, onChange, onTypeChange }) => {
          const month = value.month();
          const year = value.year();

          const monthOptions = [];
          for (let i = 0; i < 12; i++) {
            monthOptions.push(
              <Select.Option key={i} value={i}>
                {moment().month(i).format("MMMM")}
              </Select.Option>
            );
          }

          const monthSelector = (
            <Select
              value={month}
              onChange={(newMonth) => {
                const newValue = value.clone().month(newMonth);
                onChange(newValue);
              }}
              dropdownMatchSelectWidth={false}
            >
              {monthOptions}
            </Select>
          );

          return <div style={{ padding: 8 }}>{monthSelector}</div>;
        }}
        fullscreen={false}
        onSelect={onDateChange}
        disabledDate={disabledDate}
      />
      <h3
        className="mb-2"
        style={
          availableSlots()?.length > 0
            ? {}
            : { justifyContent: "center", display: "flex" }
        }
      >
        {availableSlots()?.length > 0 ? "Available Slot" : "No available slot"}
      </h3>
      {availableSlots()?.length > 0 && (
        <Row
          className="timeSlotsContainer"
          style={{ maxHeight: "130px", overflowY: "auto" }}
        >
          {availableSlots()?.map((time, index) => (
            <Col span={8} key={time}>
              <Button
                key={time}
                type={selectedTime === time ? "primary" : "default"}
                onClick={() => onTimeChange(time)}
                style={{ marginBottom: "10px" }}
              >
                {time}
              </Button>
            </Col>
          ))}
        </Row>
      )}
    </AntdModal>
  );
};

export default BookAppointment;
