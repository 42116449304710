"use client";
import { apiClient as brandClient } from "@/config/brandClient";
import { apiClient as userClient } from "@/config/userClient";
import { apiClient as leadClient } from "@/config/leadClient";

import { API_ENDPOINTS } from "@/constants/endpoints";

const { PYR } = API_ENDPOINTS;

export const getCatalogKeywords = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = PYR.getCatalogKeywords();
      const res = await brandClient({ url: url, method, params: payload });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const findInvestor = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = PYR.findInvestor();
      const res = await userClient({ url: url, method, params: payload });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const savePyrForm = (payload, removeToast) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = PYR.savePyrForm();
      const res = await leadClient({ url: url, method, data: payload, removeToast });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const updatePyrForm = (payload, pyrId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = PYR.updatePyrForm(pyrId);

      const res = await leadClient({ url: url, method, data: payload });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const verifyPyrForm = (payload, pyrId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = PYR.verifyPyrForm(pyrId);

      const res = await leadClient({ url: url, method, data: payload });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const sendOtp = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = PYR.sendOtp();

      const res = await userClient({ url: url, method, data: payload });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const directSavePyrFormToBrand = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = PYR.directSavePyrFormToBrand();
      const res = await leadClient({ url: url, method, data: payload });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const addAppointment = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = PYR.addAppointment();
      const res = await userClient({ url: url, method, data: payload });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const addAdditionalInfo = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = PYR.addAdditionalInfo(payload.id);
      const res = await leadClient({ url: url, method, data: payload });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};