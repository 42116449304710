"use client";
import React, { useMemo, useState, useEffect } from "react";
import { Button, Form, Select, Input, Col, Row } from "antd";
import CustomMultiSelect from "@/components/common/CustomSelect/CustomMultiSelect";
import CustomSlider from "@/components/common/CustomSlider/CustomSlider";
import regex from "@/config/regex";
import MultiSelectOptions from "@/components/common/CustomSelect/MultiSelectOptions";
import { toast } from "react-hot-toast";
import useAuth from "@/hooks/useAuth";
import useResponsive from "@/hooks/useResponsive";

const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 24,
  },
};
const tailLayout = {
  wrapperCol: {
    span: 24,
  },
};
const PyrForm = ({
  form,
  onFinish,
  categoryOptions,
  countryOptions,
  citiesOptions,
  handleOnCountryChange,
  categoryFetching,
  debouncedSearch,
  handlePhoneNumberChange,
  pyrData,
  loading,
  selectedCountry,
  fetchCities,
  handleOnCitySearch,
  otpSent,
  disabledEmail,
  investmentBudgetType,
  setInvestmentBudgetType,
  showCancelButton,
  closePyrModal,
  disabledMobile,
}) => {
  const { isMobile } = useResponsive();
  //console.log("selectedCountry", selectedCountry);
  //console.log("categoryOptions", categoryOptions);
  const keywordValue = Form.useWatch("keyword", form);
  const emailValue = Form.useWatch("email", form);
  const amountVal = Form.useWatch("amount", form);
  const mobile = Form.useWatch("mobile", form);
  const { user, profile } = useAuth();

  // useEffect(() => {
  //   if (amountVal > 0) {
  //     onChangeInvestmentsBudget(amountVal)
  //   }
  // }, [amountVal])
  //console.log("keyword", keywordValue);
  const isMaxValues = keywordValue && keywordValue.length === 3;
  const handleShowError = () => {
    toast.error("Maximum 3 keywords is allowed");
  };
  const getEmailValue = useMemo(() => {
    if (disabledEmail && emailValue) return true;
  }, [emailValue, disabledEmail]);

  const getMobileValue = useMemo(() => {
    if (disabledMobile && mobile) return true;
  }, [mobile, disabledMobile]);

  const regexs = {
    email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    codedEmail: /^[a-zA-Z]\*+([a-zA-Z0-9._%+-]*)@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, // Adjusted pattern
  };

  const validateEmail = (_, value) => {
    if (regex.email.test(value)) {
      return Promise.resolve();
    }
    if (regexs.codedEmail.test(value)) {
      return Promise.resolve();
    }

    return Promise.reject(new Error("Please enter a valid email address"));
  };
  // const onChangeInvestmentsBudget = (value) => {
  //   if (value >= 10000000) {
  //     setInvestmentBudgetType("crore");
  //   } else {
  //     setInvestmentBudgetType("lakh");
  //   }
  //   setInvestmentBudget(value);
  // };

  return (
    <div className="requirment__form">
      <Form
        {...layout}
        form={form}
        name="requirment"
        onFinish={onFinish}
        initialValues={{ country: "India", investment: 100000 }}
      >
        <Form.Item
          name="keyword"
          rules={[
            {
              required: true,
              message: "Please select keyword",
            },
          ]}
        >
          <MultiSelectOptions
            // optionLabelProp="label"
            mode="multiple"
            allowClear
            placeholder="Search Category or Products"
            options={categoryOptions}
            size="large"
            onSearch={debouncedSearch}
            loading={categoryFetching}
            labelInValue={true}
            {...(isMaxValues && {
              open: false,
              onDropdownVisibleChange: handleShowError,
            })}
            aria-label="Search Category or Products"
          />
        </Form.Item>
        <Col md={24}>
          <Form.Item
            name="mobile"
            rules={[
              {
                required: selectedCountry === "IN" ? true : false,
                message: "Please enter a valid mobile number",
                pattern: regex.mobile,
              },
            ]}
            validateTrigger="onBlur"
          >
            <Input
              size="large"
              placeholder="Enter Phone"
              onChange={handlePhoneNumberChange}
              disabled={getMobileValue}
              aria-label="Phone"
            />
          </Form.Item>
        </Col>

        <Row gutter={16} wrap={true}>
          <Col md={24} xs={20}>
            <Form.Item
              name="country"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <div className="flex items-center">
                <span className="max-w-fit text-color-secondary-dark ">
                  Your country is{" "}
                </span>
                <div className="ml-3  max-w-fit flex-1	">
                  <Select
                    placeholder="Select a option and change input text above"
                    size="middle"
                    onChange={handleOnCountryChange}
                    defaultValue={"India"}
                    options={countryOptions}
                    dropdownStyle={{ minWidth: "250px" }}
                    placement="bottomLeft"
                    showSearch={true}
                    filterOption={(input, option) =>
                      option?.label
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0 ||
                      option?.label
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    className="fd-custom-select" // Apply custom class here
                    style={{
                      border: "2px solid #257fd9",
                      borderRadius: "6px",
                    }}
                    aria-label="Country"
                  />
                </div>
              </div>
            </Form.Item>
          </Col>
          <Col md={24} xs={24}>
            <Form.Item
              name="email"
              // rules={
              //   getEmailValue
              //     ? [{ required: true }]
              //     : [
              //         {
              //           // required: selectedCountry === "IN" ? false : true,
              //           required: true,
              //           message: "Please enter a valid email address",
              //           pattern: regex.email,
              //         },
              //       ]
              // }
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: "Please enter a valid email address",
                  pattern: regex.email,
                  validator: validateEmail,
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter Email"
                disabled={getEmailValue}
                aria-label="Email"
              />
            </Form.Item>
          </Col>

          <Col md={24} xs={24}>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input size="large" placeholder="Enter Name" aria-label="Name" />
            </Form.Item>
          </Col>
        </Row>

        {pyrData?.name && (
          <>
            <Row gutter={16} className={isMobile ? "mb-0 mt-2" : "mb-0 mt-5"}>
              {selectedCountry === "IN" && (
                <Col md={12} sm={isMobile ? 24 : 12} xs={isMobile ? 24 : 12}>
                  <Form.Item
                    name="city"
                    rules={[
                      {
                        required: selectedCountry === "IN" ? true : false,
                      },
                    ]}
                  >
                    <CustomMultiSelect
                      mode="unset"
                      allowClear
                      placeholder="Search City"
                      //onChange={handleChange}
                      options={citiesOptions}
                      size="large"
                      labelInValue={true}
                      onSearch={handleOnCitySearch}
                      withMoreInfo
                      aria-label="Search City"
                    />
                  </Form.Item>
                </Col>
              )}
              <Col
                md={selectedCountry === "IN" ? 12 : 24}
                sm={isMobile ? 24 : 12}
                xs={isMobile ? 24 : 12}
                className={isMobile ? "mt-2" : "-mt-6"}
              >
                <div className="font-sans text-sm text-color-secondary-dark">
                  Investment budget
                </div>
                <Form.Item
                  name="amount"
                  rules={[
                    {
                      required: true,
                      message: "Please select an investment amount",
                    },
                    {
                      validator: (_, value) =>
                        value > 99
                          ? Promise.reject(
                              new Error("The maximum amount is 99")
                            )
                          : Promise.resolve(),
                    },
                  ]}
                  className="custom-input-group"
                  labelCol={{ span: 24 }}
                  // label="Investment budget"
                >
                  <Input
                    addonAfter={
                      <Select
                        defaultValue="lakh"
                        value={investmentBudgetType}
                        onChange={(value) => {
                          setInvestmentBudgetType(value);
                        }}
                        style={{ width: "80px", height: "40px" }}
                      >
                        <Option value="lakh">lakh</Option>
                        <Option value="crore">crore</Option>
                      </Select>
                    }
                    size="large"
                    placeholder="Enter Budget"
                    style={{ height: "40px" }}
                    type="number"
                    max={99}
                    min={0}
                    aria-label="Budget"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              name="message"
              rules={[
                {
                  required: true,
                  message: "Please Enter Enquiry Message",
                },
              ]}
            >
              <Input.TextArea
                showCount
                maxLength={200}
                rows={3}
                placeholder="Enter Enquiry Message"
                aria-label="Enquiry Message"
              />
            </Form.Item>
          </>
        )}

        <Form.Item {...tailLayout}>
          <div style={{ display: "flex" }}>
            {showCancelButton && (
              <Button
                type="default"
                block={true}
                size={showCancelButton ? "small" : "large"}
                className="mt-4"
                onClick={closePyrModal}
                style={{ marginRight: "5px", height: "40px" }}
              >
                Cancel
              </Button>
            )}
            <Button
              type="primary"
              htmlType="submit"
              block={true}
              size={showCancelButton ? "small" : "large"}
              className="mt-4"
              loading={loading}
              disabled={otpSent}
              style={
                showCancelButton ? { marginRight: "5px", height: "40px" } : {}
              }
            >
              Submit
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PyrForm;
