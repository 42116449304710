"use client";

import React, { useState } from "react";
import { Button, Input, Alert } from "antd";
import AntdModal from "@/components/common/Modal/AntdModal";
import * as PYRServices from "@/services/PYRServices";
import useAuth from "@/hooks/useAuth";

const PyrOtpVerify = ({
  showModal,
  setShowModal,
  pyrData,
  setShowOtpSuccessModal,
  selectedCountry,
  pyrId,
  handleClose
}) => {
  const [otpInput, setOtpInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [resending, setResending] = useState(false);
  const [error, setError] = useState({
    showAlert: false,
    message: null,
    type: "error",
  });
  const { user, authLogin } = useAuth();
  const handleCloseModal = () => {
    
    // setShowModal(false);
    handleClose()
    setError({
      showAlert: false,
      message: null,
      type: "error",
    });
  };

  const handleVerifyOtp = async () => {
    setLoading(true);
    let payload = {
      otp: +otpInput,
    };
    if(pyrData?.email && selectedCountry!=="IN"){
      payload.email = pyrData?.email || ""
    }
    if(pyrData?.mobile){
      payload.mobile = +pyrData?.mobile || ""
    }
    try {
      const response = await PYRServices.verifyPyrForm(payload, (pyrId || pyrData?._id));

      setLoading(false);

      if (response?.status === "Success") {
        if(!user){
          authLogin(response?.data?.data);
        }
        setShowModal(false);
        setShowOtpSuccessModal(true);
      }
      if (response?.status === "Failed") {
        setError({
          showAlert: true,
          message: response?.message,
          type: "error",
        });
      }
      setOtpInput("");
    } catch (error) {
      setLoading(false);
      setOtpInput("");
    }
  };

  const resendOtp = async () => {
    setResending(true);
    console.log("resend otp call", pyrData);
    let otpMobile = undefined;
    let otpEmail = undefined;
    if(pyrData?.country !== "+91"){
      otpEmail = pyrData?.email;
    }else{
      otpMobile = pyrData?.mobile;
    }

    let payload = {
      email: otpEmail,
      mobile: otpMobile,
      countrycode: pyrData?.country || "+91",
    };
    try {
      const response = await PYRServices.sendOtp(payload);
      console.log("resendOtp", response);
      if (response?.status === "Success") {
        setError({
          showAlert: true,
          type: "success",
          message: response.message,
        });
      }
      if (response?.status === "Failed") {
        setError({
          showAlert: true,
          message: response?.message,
          type: "error",
        });
      }
    } catch (error) {
      const message = error?.data?.error?.err_message?.toString() || "Failed!";
      setError({
        showAlert: true,
        message: message,
        type: "error",
      });
    } finally {
      setResending(false);
    }
  };
  //console.log("error", error);

  return (
    <AntdModal
      showModal={showModal}
      handleCancelModal={handleCloseModal}
      footer={null}
      modalTitle={null}
    >
      <div className="pyrotp_verify">
        <h5 className="mb-2 font-sans text-lg text-color-primary">
          OTP Verification
        </h5>
        <p className="font-sans text-sm text-color-primary-dark">
          {`Enter OTP which we sent to your ${selectedCountry === "IN"?"mobile no.":"Email address."}`}
        </p>

        {error?.showAlert && (
          <div className="mt-2">
            <Alert message={error?.message} type={error?.type} showIcon />
          </div>
        )}

        <div className="mb-2 mt-4">
          <Input
            placeholder="Enter OTP"
            size="large"
            onChange={(e) => setOtpInput(e.target.value)}
            value={otpInput}
          />
        </div>
        <div className="mb-4 flex flex-row items-center justify-start space-x-1 text-left text-sm font-medium text-gray-500">
          <p className="font-sans text-sm font-medium text-color-secondary-dark">
            Didn't recieve code?
          </p>{" "}
          <Button
            type="link"
            htmlType="button"
            block={false}
            size="large"
            className="flex flex-row items-center pl-1 text-sm font-semibold text-color-link"
            onClick={resendOtp}
            loading={resending}
          >
            {resending ? "Sending..." : "Resend OTP"}
          </Button>
          {/* <button
            className="flex flex-row items-center font-sans font-semibold text-color-link"
            onClick={() => resendOtp()}
          >
            Resend OTP
          </button> */}
        </div>

        <div>
          <Button
            type="primary"
            htmlType="submit"
            block={true}
            size="large"
            className="mt-4 font-sans text-sm"
            onClick={handleVerifyOtp}
            loading={loading}
          >
            Submit OTP
          </Button>
        </div>
      </div>
    </AntdModal>
  );
};

export default PyrOtpVerify;
