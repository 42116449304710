"use client";

import { Select } from "antd";
import "./CustomSelect.Style.scss";
import { forwardRef } from "react";
const { Option } = Select;

const CustomMultiSelect = forwardRef(function CustomMultiSelect(
  {
    options = [],
    defaultValue = [],
    placeholder = "Please select",
    register,
    mode = "multiple",
    onSearch,
    loading = false,
    withMoreInfo=false,
    ...rest
  },
  ref
) {
  //console.log('field', rest);
  return (
    withMoreInfo ? <Select
      mode={mode}
      className="select-antd-wrapper"
      allowClear={mode === "multiple" || mode === "tags"}
      style={{
        width: "100%",
      }}
      placeholder={placeholder}
      // options={options}
      ref={ref}
      showSearch
      filterOption={
        onSearch
          ? false
          : (input, option) =>
            option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      dropdownStyle={{
        //zIndex: 1072,
        zIndex: 1200,
      }}
      onSearch={onSearch}
      loading={loading}
      //maxTagCount={5}
      {...rest}
    >
      {(options || []).map((option) => (
        <Option key={option._id} value={option.value}>
          <div>
            <span>{option.label} - {option.stateCode}</span>
          </div>
        </Option>
      ))}
    </Select> :
      <Select
        mode={mode}
        className="select-antd-wrapper"
        allowClear={mode === "multiple" || mode === "tags"}
        style={{
          width: "100%",
        }}
        placeholder={placeholder}
        options={options}
        ref={ref}
        showSearch
        filterOption={
          onSearch
            ? false
            : (input, option) =>
              option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        dropdownStyle={{
          //zIndex: 1072,
          zIndex: 1200,
        }}
        onSearch={onSearch}
        loading={loading}
        //maxTagCount={5}
        {...rest}
      />
  );
});

export default CustomMultiSelect;
