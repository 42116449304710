"use client";

import React from "react";
import { Slider } from "antd";
import "./CustomSlider.Style.scss";
import {
  formatValueInCroresAndLakhs,
  numberWithCommas,
} from "../../../utils/helper";

const CustomSlider = ({
  defaultValue,
  draggableTrack = true,
  range = true,
  tooltipOpen = true,
  tooltipPlacement = "right",
  tooltipType = "price",
  ...rest
}) => {
  //  console.log('range', rest);

  const formatTooltipText = (value) => {
    let formatTypeMapping = {
      default: function () {
        return value;
      },
      number: numberWithCommas,
      price: formatValueInCroresAndLakhs,
    };
    return formatTypeMapping[tooltipType](value);
  };

  return (
    <Slider
      //range={{ draggableTrack: draggableTrack }}
      range={range}
      defaultValue={defaultValue}
      tooltip={{
        //open: tooltipOpen,
        placement: tooltipPlacement,
        zIndex: 2147483647,
        formatter: (value) => <span>{formatTooltipText(value)}</span>,
        getPopupContainer: (triggerNode) => triggerNode.parentNode,
      }}
      railStyle={{
        height: "5px",
        backgroundColor: "#285bd14d",
      }}
      trackStyle={{
        backgroundColor: "#285bd1",
      }}
      {...rest}
    />
  );
};

export default CustomSlider;
