"use client";

import { Modal } from "antd";
import { useEffect } from "react";

const AntdModal = ({
  children,
  showModal,
  handleCancelModal,
  handleOkModal,
  modalTitle,
  zIndex = 1200,
  centered = true,
  ...rest
}) => {
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [showModal]);

  return (
    <Modal
      title={modalTitle}
      open={showModal}
      onOk={handleOkModal}
      onCancel={handleCancelModal}
      centered={centered}
      zIndex={zIndex}
      getContainer="#antdmodal"
      {...rest}
    >
      {children}
    </Modal>
  );
};

export default AntdModal;
