"use client";

import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "@/store/slices/authModalSlice";

const useAuthModal = () => {
  const dispatch = useDispatch();
  const { showModal, pageName } = useSelector((state) => state?.authModal);
  const openAuthModal = (page) => {
    dispatch(openModal(page));
  };

  const closeAuthModal = () => {
    dispatch(closeModal());
  };

  return {
    openAuthModal,
    closeAuthModal,
    showModal,
    pageName,
  };
};

export default useAuthModal;
