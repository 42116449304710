"use client";

import { useDispatch, useSelector } from "react-redux";
import {
  openPyrModal as openModal,
  closePyrModal as closeModal,
} from "@/store/slices/authModalSlice";

const usePyrModal = () => {
  const dispatch = useDispatch();
  const { showPyrModal } = useSelector((state) => state?.authModal);
  const openPyrModal = () => {
    dispatch(openModal());
  };

  const closePyrModal = () => {
    dispatch(closeModal());
  };

  return {
    openPyrModal,
    closePyrModal,
    showPyrModal,
  };
};

export default usePyrModal;
