"use client";

import { Form, Modal, Button } from "antd";
import { useRouter } from "next/navigation";
import { useEffect, useMemo, useState } from "react";
import debounce from "lodash/debounce";
import {
  getLabelValueOptions,
  getCountryOptions,
  getKeywordOptions,
  getValueInLakhOrCrore,
} from "@/utils/helper";
import { getCitiesData } from "@/services/MiscServices";
import PyrForm from "@/features/LandingPage/pyr/PyrForm";
import * as PYRServices from "@/services/PYRServices";
import PyrOtpVerify from "@/features/LandingPage/pyr/PyrOtpVerify";
import ClientOnly from "@/components/common/ClientOnly";
import PyrSuccess from "@/features/LandingPage/pyr/PyrSuccess";
import "@/features/LandingPage/homepage.scss";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";
import useAuth from "@/hooks/useAuth";
import uniqBy from "lodash/uniqBy";
import concat from "lodash/concat";
import BookAppointment from "@/features/LandingPage/pyr/BookAppointment";
import moment from "moment";
import { setInvestorData } from "@/store/slices/authInvestoreData";
import PyrAdditioanlInfo from "./PyrAdditioanlInfo";
import AntdModal from "@/components/common/Modal/AntdModal";
import usePyrModal from "@/hooks/usePyrModal";

const PostYourRequirment = ({ hideHeader, showCancelButton }) => {
  const router = useRouter();
  const { investorData } = useSelector((state) => state?.investorDetails);
  const dispatch = useDispatch();
  const { closePyrModal } = usePyrModal();

  const [categoryFetching, setCategoryFetching] = useState(false);
  const [searchCategoryText, setSearchCategoryText] = useState("");
  const [categoryKeywords, setCategoryKeywords] = useState([]);
  const [categoryKeywordsCopy, setCategoryKeywordsCopy] = useState([]);
  const [investorDetails, setInvestorDatas] = useState({});
  const [pyrData, setPyrData] = useState({});
  const [loading, setLoading] = useState(false);
  //OTP verify
  const [showOtpVerifyModal, setShowOtpVerifyModal] = useState(false);
  const [showOtpSuccessModal, setShowOtpSuccessModal] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [investmentBudgetType, setInvestmentBudgetType] = useState("lakh");
  const [subscriptionState, subsciptionModalState] = useState(false);
  const [errorMsg, subscriptionErrorMsg] = useState("");
  const [supportBtn, showSupportBtn] = useState(false);
  const [subscriptionBtn, showSubscriptionBtn] = useState(false);

  const [state, setState] = useState({ additionalModal: false });
  const { additionalModal } = state;
  //countries
  const [cities, setCities] = useState([]);
  const [showAppointermodal, setBookAppointeModalState] = useState(false);
  const [isFromAdditional, setIsFromAdditional] = useState(false);
  //selected country
  const [selectedCountry, setSelectedCountry] = useState("IN");

  const { countries } = useSelector((state) => state?.misc);
  const { user, profile } = useAuth();
  //console.log("countries", countries);
  const [form] = Form.useForm();
  const { getFieldValue, setFieldValue, resetFields } = form;

  const fetchCities = async (countryCode = "IN", searchText) => {
    let params = {
      countryCode: countryCode,
      search: searchText || undefined,
    };
    const response = await getCitiesData(params);
    if (response.status === "Success") {
      setCities(response?.data);
    }
  };

  const handleSearch = (value) => {
    setSearchCategoryText(value);
  };
  const debouncedSearch = debounce(handleSearch, 500);
  const handleOnCategorySearch = async () => {
    setCategoryFetching(true);
    try {
      const payload = {
        search: searchCategoryText || undefined,
      };
      // console.log("payload", payload);
      const response = await PYRServices.getCatalogKeywords(payload);
      if (response.status === "Success") {
        // console.log(response?.data);
        setCategoryKeywords(response?.data);
        const categories = concat(response?.data, categoryKeywordsCopy);
        setCategoryKeywordsCopy(categories);
      }
    } catch (error) {
    } finally {
      setCategoryFetching(false);
    }
  };

  useEffect(() => {
    if (!searchCategoryText) return;
    const callFn = async () => {
      if (searchCategoryText) {
        await handleOnCategorySearch();
      }
    };
    callFn();
  }, [searchCategoryText]);

  useEffect(() => {
    if (user?.mobile) {
      // handleFindInvestor(profile?.mobile)
      setFieldValue("mobile", profile?.mobile || user?.mobile);
    }
    if (user?.email || profile?.email) {
      setFieldValue("email", profile?.email || user?.email);
    }
  }, []);

  // useEffect(() => {
  //   fetchCities();
  // }, []);

  const handleChange = (value) => {
    // console.log(`handleChange ${value}`);
  };

  const handleOnCountryChange = (value) => {
    fetchCities(value);
    setSelectedCountry(value);
  };

  const handleOnCitySearch = debounce((value) => {
    fetchCities(selectedCountry, value);
  }, 300);

  const handleInvestmentBudgetChange = (value) => {
    //console.log("handleInvestmentBudgetChange", value);
  };

  const handleFindInvestor = debounce(async (phoneNumber) => {
    try {
      const payload = {
        search: phoneNumber || undefined,
      };
      const response = await PYRServices.findInvestor(payload);
      console.log("response", response);
      setInvestorDatas(response?.data);
      dispatch(setInvestorData(response?.data));

      setFieldValue("email", response?.data?.email || "");
    } catch (error) {}
  }, 500);

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    if (value?.length > 9) {
      handleFindInvestor(value);
    }
  };
  const savePyrForm = async (data) => {
    let keywordsArray = [];
    const valuesId = data?.keyword?.map((vl) => vl?.value);
    categoryKeywordsCopy
      ?.filter((cat) => valuesId?.includes(cat?.keyword_id))
      ?.map((vls) => {
        keywordsArray.push({
          keyword_id: vls.keyword_id,
          keyword_name: vls.keyword_name,
          cat_id: vls.cat_id,
          cat_name: vls.cat_name,
          master_cat_id: vls.master_cat_id,
        });
      });
    keywordsArray = uniqBy(keywordsArray, "keyword_id");
    data.keyword = keywordsArray;
    // categoryKeywordsCopy.forEach((cat) => {
    //   data?.keyword.forEach((el) => {
    //     if (el.value === cat.keyword_id) {
    //       keywordsArray.push({
    //         keyword_id: cat.keyword_id,
    //         keyword_name: cat.keyword_name,
    //         cat_id: cat.cat_id,
    //         cat_name: cat.cat_name,
    //         master_cat_id: cat.master_cat_id,
    //       });
    //     }
    //   });
    // });
    //console.log("keywordsArray", keywordsArray);
    setLoading(true);
    let payload = {
      keywords: keywordsArray,
      investor_id:
        investorDetails?.investor_id ||
        investorDetails?._id ||
        investorData?.investor_id ||
        investorData?._id ||
        "",
      name: data?.name,
      country:
        countries.find((el) => el.isoCode === selectedCountry)?.phoneCode ||
        "+91",
      mobile: +data?.mobile || undefined,
      email: data?.email || undefined,
      enquiry_source: "Homepage",
    };

    try {
      // const stopToast = user ? false : true
      const stopToast = true;

      const response = await PYRServices.savePyrForm(payload, stopToast);
      // console.log("response", response);
      if (response.status === "Success") {
        setPyrData(response?.data);
        setLoading(false);
        setInvestorDatas(response?.data);
        dispatch(setInvestorData(response?.data));
      }
      if (response.status === "Failed") {
        toast.error(response?.error);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // if (!user) {
      subsciptionModalState(true);
      if (
        error?.data?.error?.err_message ===
        "You cannot Post your Requirement, Since your previous requirements are still under process. Please contact Support"
      ) {
        subscriptionErrorMsg(`Unable to Post new requirements as previous ones are still processing. Contact Support for help. 
Support details are available in the Help section.`);
        showSupportBtn(true);
        showSubscriptionBtn(false);
      } else if (
        error?.data?.error?.err_message ===
        "Your Requirement count has been consumed, please buy another plan"
      ) {
        subscriptionErrorMsg(error?.data?.error?.err_message);
        showSupportBtn(false);
        showSubscriptionBtn(true);
      } else {
        subscriptionErrorMsg(error?.data?.error?.err_message);
        showSupportBtn(false);
        showSubscriptionBtn(false);
      }
      // }
      // toast.error(error?.data?.error?.err_message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const updatePyrForm = async (data) => {
    console.log("datadata", data);
    const findCity = cities?.find((vl) => vl?._id === data?.city?.value);
    setLoading(true);
    let payload = {
      city: {
        name: findCity?.name,
        _id: findCity?._id,
      },
      // amount: parseInt(data?.amount),
      amount: getValueInLakhOrCrore(data?.amount, investmentBudgetType),
      message: data?.message,
    };
    //console.log("updatePyrForm payload", payload);
    try {
      const response = await PYRServices.updatePyrForm(payload, pyrData?._id);
      // console.log("updatePyrForm response", response);
      if (response.status === "Success") {
        setShowOtpVerifyModal(true);
        //setOtpSent(true);
        setLoading(false);
      }
      if (response.status === "Failed") {
        toast.error(response?.error);
        setLoading(false);
      }
    } catch (error) {
      console.log("errorssss", error);
      toast.error(error?.response?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const onFinish = (values) => {
    console.log("valuesvalues", values);
    if (pyrData?.name) {
      updatePyrForm(values);
    } else {
      savePyrForm(values);
    }
  };
  const categoryOptions = useMemo(() => {
    return getKeywordOptions(categoryKeywords);
  }, [categoryKeywords, setCategoryKeywords]);

  const countryOptions = useMemo(() => {
    return getCountryOptions(countries);
  }, [countries]);
  const citiesOptions = useMemo(() => {
    return getLabelValueOptions(cities);
  }, [cities, setCities]);

  // const country = Form.useWatch("country", form);
  // console.log("countryOptionscountryOptionscountryOptions", countryOptions);

  // console.log("selectedCountryselectedCountry", selectedCountry);

  const isEmailExist = useMemo(() => {
    if (user && user?.email) {
      return true;
    } else if (!user && (investorDetails?.email || investorData?.email)) {
      return true;
    }
    return false;
  }, [user?.email, investorDetails?.email, investorData?.email, setFieldValue]);

  const isMobileExist = useMemo(() => {
    if (user && (user?.mobile || profile?.mobile)) {
      return true;
    }
    //  else if (investorDetails?.mobile || investorData?.mobile) {
    //   return true
    // }
    return false;
  }, [user?.mobile, investorDetails?.mobile, investorData?.mobile]);

  useEffect(() => {
    if (!user) {
      resetFields();
    }
  }, [user]);

  const bookAppointment = () => {
    setBookAppointeModalState(true);
    setShowOtpSuccessModal(false);
  };

  // Function to add ordinal suffix based on the day number
  function addOrdinalSuffix(day) {
    if (day >= 11 && day <= 13) {
      return day + "th";
    }
    switch (day % 10) {
      case 1:
        return day + "st";
      case 2:
        return day + "nd";
      case 3:
        return day + "rd";
      default:
        return day + "th";
    }
  }

  const submitAppointment = async (date, timeSlot) => {
    try {
      if (
        !user ||
        investorDetails?.investor_id ||
        investorDetails?._id ||
        investorData?._id
      ) {
        const payload = {
          investor_id:
            investorDetails?.investor_id ||
            investorDetails?._id ||
            investorData?._id ||
            "",
          date: moment(date).format("YYYY-MM-DD"),
          time: moment(timeSlot, "hh:mm A").format("HH:mm"),
        };
        const response = await PYRServices.addAppointment(payload);
        console.log("responseresponse", response);
        if (response?.status === "Success") {
          // Extract day and format date correctly
          const day = moment(date, "YYYY-MM-DD").format("Do");
          const formattedDate = `${day} ${moment(date).format("MMMM")}`;
          console.log("formattedDateformattedDate", formattedDate);

          toast.success(
            `Thank you for reaching out! Our team will contact you at ${formattedDate} ${timeSlot}. We look forward to speaking with you!`
          );
          setBookAppointeModalState(false);
          const emailvl = getFieldValue("email");
          const mobvl = getFieldValue("mobile");
          resetFields();
          setFieldValue("email", emailvl);
          setFieldValue("mobile", mobvl);
        }
      } else {
        toast.error("Investor id is missing");
      }
    } catch (error) {
      console.log("errorerrorerrorerror", error);
      toast.error(error?.response?.message || "Something went wrong");
    }
  };

  const handlePyrSuccess = () => {
    setShowOtpSuccessModal(true);
    setState((prev) => ({ ...prev, additionalModal: false }));
    setIsFromAdditional(true);
    closePyrModal();
  };
  return (
    <>
      <div
        className={
          hideHeader
            ? "flex w-full flex-col justify-end"
            : "ml-auto flex w-full flex-col justify-end md:w-2/6"
        }
      >
        {!hideHeader && (
          <h4 className="mb-5 text-2xl font-semibold  text-color-primary">
            Post your requirement
          </h4>
        )}
        <PyrForm
          form={form}
          onFinish={onFinish}
          handleOnCountryChange={handleOnCountryChange}
          handlePhoneNumberChange={handlePhoneNumberChange}
          debouncedSearch={debouncedSearch}
          categoryOptions={categoryOptions}
          countryOptions={countryOptions}
          citiesOptions={citiesOptions}
          categoryFetching={categoryFetching}
          pyrData={pyrData}
          loading={loading}
          selectedCountry={selectedCountry}
          handleOnCitySearch={handleOnCitySearch}
          fetchCities={fetchCities}
          otpSent={otpSent}
          disabledEmail={isEmailExist}
          setInvestmentBudgetType={setInvestmentBudgetType}
          investmentBudgetType={investmentBudgetType}
          showCancelButton={showCancelButton}
          closePyrModal={closePyrModal}
          disabledMobile={isMobileExist}
        />
      </div>

      {showOtpVerifyModal && (
        <ClientOnly>
          <PyrOtpVerify
            showModal={showOtpVerifyModal}
            setShowModal={setShowOtpVerifyModal}
            pyrData={pyrData}
            // setShowOtpSuccessModal={setShowOtpSuccessModal}
            setShowOtpSuccessModal={() =>
              setState((prev) => ({ ...prev, additionalModal: true }))
            }
            selectedCountry={selectedCountry}
            handleClose={() => setShowOtpVerifyModal(false)}
          />
        </ClientOnly>
      )}

      <ClientOnly>
        <PyrSuccess
          showModal={showOtpSuccessModal}
          setShowModal={setShowOtpSuccessModal}
          pyrData={pyrData}
          setPyrData={setPyrData}
          form={form}
          bookAppointment={bookAppointment}
          isFromAdditional={isFromAdditional}
        />
      </ClientOnly>
      <ClientOnly>
        {showAppointermodal && (
          <BookAppointment
            showModal={showAppointermodal}
            setShowModal={setBookAppointeModalState}
            onSuccess={submitAppointment}
          />
        )}
      </ClientOnly>
      <Modal
        className="confirmModal"
        wrapClassName="confirmModalWrapper"
        title={supportBtn ? "" : subscriptionBtn ? "Subscribe" : ""}
        open={subscriptionState}
        onCancel={() => {
          subsciptionModalState(false);
          subscriptionErrorMsg("");
        }}
        centered={true}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              subsciptionModalState(false);
              subscriptionErrorMsg("");
            }}
            type={"default"}
            className="cancelButton"
          >
            {supportBtn ? "Cancel" : "Close"}
          </Button>,
          subscriptionBtn && (
            <Button
              key="confirm"
              type={"primary"}
              onClick={() => router.push("/subscription")}
              className="confirmButton"
            >
              Subscription
            </Button>
          ),
        ]}
      >
        <div className="confirmModalBody">
          <p className="onfirmModalContent">{`${errorMsg}`}</p>
        </div>
      </Modal>
      {additionalModal && (
        <AntdModal
          showModal={additionalModal}
          handleCancelModal={handlePyrSuccess}
          footer={null}
          modalTitle={"Additional Info"}
          width={800}
        >
          <PyrAdditioanlInfo
            handlePyrSuccess={handlePyrSuccess}
            pyrData={pyrData}
            handleClose={handlePyrSuccess}
          />
        </AntdModal>
      )}
    </>
  );
};

export default PostYourRequirment;
