"use client";
import { apiClient as brandClient } from "@/config/brandClient";
import { apiClient as masterClient } from "@/config/masterClient";
import { API_ENDPOINTS } from "@/constants/endpoints";
const { COUNTRY, MISC } = API_ENDPOINTS;

export const getCountriesData = async (params) => {
  try {
    const { url, method } = COUNTRY.getCountries();
    const res = await masterClient({ url, method, params });
    return res;
  } catch (error) {
    return error;
  }
};

export const getStatesData = async (params) => {
  try {
    const { url, method } = COUNTRY.getStates();
    const res = await masterClient({ url, method, params });
    return res;
  } catch (error) {
    return error;
  }
};

export const getCitiesData = async (params) => {
  try {
    const { url, method } = COUNTRY.getCities();
    const res = await masterClient({ url, method, params });
    return res;
  } catch (error) {
    return error;
  }
};

export const getHeaderData = async (params) => {
  try {
    const { url, method } = MISC.getHeaderData();
    const res = await masterClient({ url, method, params });
    return res;
  } catch (error) {
    return error;
  }
};
export const getDropdownData = async () => {
  try {
    const { url, method } = MISC.getDropdownData();
    const res = await brandClient({ url, method });
    return res;
  } catch (error) {
    return error;
  }
};

export const getIndustryData = async () => {
  try {
    const { url, method } = MISC.getIndustry();
    const res = await masterClient({ url, method });
    return res;
  } catch (error) {
    return error;
  }
};
export const getAllCategory = async () => {
  try {
    const { url, method } = MISC.getAllCategory();
    const res = await masterClient({ url, method });
    return res;
  } catch (error) {
    return error;
  }
};

export const getConnectedBrands = async () => {
  try {
    const { url, method } = MISC.getConnectedBrands();
    const res = await brandClient({ url, method });
    return res;
  } catch (error) {
    return error;
  }
};

export default {
  getCountriesData,
  getStatesData,
  getCitiesData,
  getDropdownData,
  getIndustryData,
  getConnectedBrands,
  getAllCategory,
};
