// import React, { useState, useEffect } from "react";

// const useResponsive = () => {
//   const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

//   useEffect(() => {
//     const handleResize = () => {
//       setViewportWidth(window.innerWidth);
//     };

//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   const isMobile = viewportWidth <= 768;
//   const isTablet = viewportWidth >= 768 && viewportWidth <= 1024;
//   const isMobileOrTablet = viewportWidth <= 1024;
//   const isDesktop = viewportWidth > 1024;

//   return { viewportWidth, isMobile, isTablet, isDesktop, isMobileOrTablet };
// };

// export default useResponsive;
"use client";

import { useState, useEffect } from "react";

const useResponsive = () => {
  const [viewportWidth, setViewportWidth] = useState(() => {
    // Check if window is defined (client-side) to avoid ReferenceError during SSR
    return typeof window !== "undefined" ? window.innerWidth : 0;
  });

  useEffect(() => {
    // Only add the event listener if we're on the client
    if (typeof window !== "undefined") {
      const handleResize = () => {
        setViewportWidth(window.innerWidth);
      };

      window.addEventListener("resize", handleResize);

      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  const isMobile = viewportWidth < 768;
  const isTablet = viewportWidth >= 768 && viewportWidth <= 992;
  const isLargeTablet = viewportWidth > 992 && viewportWidth <= 1024;
  const isMobileOrTablet = viewportWidth <= 992;
  const isDesktop = viewportWidth > 1024;

  return {
    viewportWidth,
    isMobile,
    isTablet,
    isDesktop,
    isMobileOrTablet,
    isLargeTablet,
  };
};

export default useResponsive;
